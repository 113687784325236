/*
	VARIABLES.SCSS
	-----------
	Contains Media Queries, Colors, Variables

	@import 'src/scss/_variables.scss';
*/

$app-width:100%;    // 1080px
$app-height:100%;   // 1920px

$font: 'lato', sans-serif;


//////////////////////////////////////////// MEDIA QUERIES
// @media #{$mq-large}{}
$mq-huge: '(max-width:1040px)';
$mq-large: '(max-width:900px)';
$mq-medium2: '(max-width:750px)';
$mq-medium: '(max-width:600px)';
$mq-small: '(max-width:450px)';
$mq-mobile: '(max-width:360px)';


//////////////////////////////////////////// COLORS
$color-primary: #1552d8;
$color-tint: #0fbb34;
$color-title: #13151A;
$color-text: #4D4D4D;
$color-line: #EBEBEB;
$color-bg: #F7F7F7;
