@use 'sass:math';

$color-input-border: #bababa;
$color-input-border-focus: $color-primary;
$color-error: #E9482B;

/* input */
input[type=text],
input[type=password], 
input[type=email],
input[type=tel],
input[type=url],
input[type=number],
input[type=search],
input[type=time],
input[type=color],
input[type=range],
textarea,
select {
	width: 100%;
	padding: 8px 8px;
	color: #202020;
	font-size: 14px;
	line-height: 14px;
	background-color: transparent;
	font-weight: 400;
	border: 1px solid $color-input-border;
	margin-bottom: 5px;
	font-family:$font;
	transition:all 0.15s ease-out;
	-webkit-appearance: none;

	&[disabled],
	&[readonly] {
		//color: #999;
		-webkit-user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	&.ng-invalid {
		//animation: error_shake 0.4s 1 linear;
	}

	&.ng-invalid:active {
		//animation:none;
	}
}

input[type=color]{
	width: 26px;
	height: 26px;
	padding: 0;
	display: inline-block;
	vertical-align: middle;
	&::-webkit-color-swatch-wrapper {
		padding: 1px;
	}
	&::-webkit-color-swatch {
		border-radius: 3px;
		border: none;
		overflow: hidden;
	}
}


input[type=range]{
	padding: 0;
	height: 14px;
	border-radius: 14px;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 100%;
	position: relative;
	top: 2px;
	border-radius: 3px;
	cursor: pointer;
 }
 input[type=range]::-webkit-slider-thumb {
	 -webkit-appearance: none;
	border: none;
	height: 100%;
	width: 40px;
	border-radius: 12px;
	background: $color-primary;
	cursor: pointer;
	margin-top: -3px;
 }

input.error,
textarea.error{
	border-color:$color-error;
}

input:focus,
textarea:focus,
input.customkeyboard-focus,
textarea.customkeyboard-focus{
  border-color:$color-input-border-focus;
}

input.error:focus,
textarea.error:focus {
	border-color:$color-input-border-focus;
}


/* PLACEHOLDER text (keep seperate entries for each browser!) */
input::-webkit-input-placeholder{ font-style:italic; }
input:-moz-placeholder{ font-style:italic; }
input::-moz-placeholder{ font-style:italic; }
input:-ms-input-placeholder{ font-style:italic; }


/* label */
label{
	font-size: 14px;
	margin-top: 20px;
	display: block;
}

textarea {
  resize: vertical;
}

/* error output */
form {
	h1{
		margin-bottom: 1em;
	}
	h2{
		margin-bottom: 0.8em;
	}
	h3{
		margin-bottom: 0.6em;
	}

  .errors {
	 display: block;
	 margin-top: 40px;
	 
	 p{
		padding: 4px 8px;
		border: 1px solid #C3F4DF;
		font-weight: 500;
		margin-bottom: 30px;
		background-color: #FEF3F9;
		border-color: #F5DBDA;
		color: $color-error;
		//display: inline-block;
	 }

    pre {
      word-wrap: break-word;
      white-space: pre-wrap;
      font-size: 12px;
      line-height: 14px;
      color: #000;
      margin-top: 10px;
      font-weight: 300;
    }
  }


  /* /////////////////////////////////////////////////////////////////////// SUBMIT BUTTON */
  button[type=submit] {
    font-family: $font;
  }

}



/* /////////////////////////////////////////////////////////////////////// RADIOBUTTON / CHECKBOX */
.custom-radiobutton,
.custom-checkbox {
	display: block;
	margin-top: 10px;
	cursor: pointer;

	&.disabled{
		pointer-events:none;
		opacity: 0.5;
	}

	input {
		position: absolute;
		margin: 5px;
		padding: 0;
		visibility: hidden;
	}

	.text {
		margin-left: 2.1em;
		margin-bottom: 0;
		position: relative;
	}
	
	.icon {
		position: absolute;
		display: block;
		top: 0px;
		left: -2.1em;
		width: 18px;
		height: 18px;
		border: 2px solid #202020;
		background-color: white;
		border-radius: 4px;
		
		// colored background on select:
		&:after {
			content: "";
			opacity: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			background: $color-primary;
			transform: translateX(-50%) translateY(-50%);
			width: 100%;
			height: 100%;
			border: 2px solid $color-primary;
			transition: opacity 0.1s ease-out;
			border-radius: 4px;
		}
	}
	
	// radiobutotn: bolleke:
	&.custom-radiobutton{
		.icon{
			&, &:after {
				border-radius: 50%;
			}
			&:before {
				content: "";
				position: absolute;
				display: block;
				z-index: 1;
				top: 50%;
				left: 50%;
				opacity: 0;
				height: 6px;
				width: 6px;
				background-color: #fff;
				border-radius: 50%;
				transform: translateX(-50%) translateY(-50%);
			}
		}
	}

	// checkbox: vinkje:
	&.custom-checkbox{
		.icon:before {
			content: "";
			position: absolute;
			display: block;
			z-index: 1;
			top: 50%;
			left: 50%;
			opacity: 0;
			height: 6px;
			width: 4px;
			border-bottom: 2px solid #FFF;
			border-right: 2px solid #FFF;
			transform: translateX(-50%) translateY(-65%) rotate(45deg);
		}
	}

	// checked state:
	input:checked+span .icon:after,
	input:checked+span .icon:before{
		opacity: 1;
	}
}

	



/* /////////////////////////////////////////////////////////////////////// SELECT DROPDOWN */
select {
	max-width: 240px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, #0D0D0D 50%), linear-gradient(135deg, #0D0D0D 50%, transparent 50%);
	background-position: calc(100% - 16px) calc(1em - 2px), calc(100% - 11px) calc(1em - 2px);
	background-size: 5px 5px, 5px 5px;
	background-repeat: no-repeat;
	padding: 8px;
}

select::-ms-expand {
	display: none;
}

select option {
	font-weight: normal;
}



/* /////////////////////////////////////////////////////////////////////// ERROR SHAKE */
.error_shake {
  animation: error_shake 0.4s 1 linear;
}

$shake_offset:20px;

@keyframes error_shake {
  0% {
    transform: translate($shake_offset);
  }

  20% {
    transform: translate(-$shake_offset);
  }

  40% {
    transform: translate(math.div($shake_offset, 2));
  }

  60% {
    transform: translate(-1* math.div($shake_offset, 2));
  }

  80% {
    transform: translate(math.div($shake_offset, 3));
  }

  100% {
    transform: translate(0px);
  }
}
