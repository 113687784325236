$speed:0.6s;
$speedSlow:2s;
$speedSlower:4s;
$speedFast:0.15s;

$slideDistance:100px;
$slideDuration:0.5s;

$animationDelayShort:0.1s;
$animationDelayMedium:0.3s;
$animationDelayLong:0.5s;

///////////////////////////////////////////////////////////// CUSTOM EASING
// https://cloud.netlifyusercontent.com/assets/344dbf88-fdf9-42bb-adb4-46f01eedd629/02de9f56-5d84-4ef2-8a19-17fdfcaed801/14-easing-functions-css-animations-transitions.png
// Cubic
$easeInCubic    : cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeOutCubic   : cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$easeInCirc     : cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCirc    : cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$easeInExpo     : cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeOutExpo    : cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$easeInQuad     : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeOutQuad    : cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$easeInQuart    : cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeOutQuart   : cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$easeInQuint    : cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeOutQuint   : cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$easeInSine     : cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeOutSine    : cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$easeInBack     : cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutBack    : cubic-bezier(0.175,  0.885, 0.320, 1.275);
$easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);


///////////////////////////////////////////////////////////// <ALL>
.fadeIn, .scaleUp, .scaleUpMedium, .scaleUpBig, .slideRight, .slideLeft, .slideUp, .slideDown{
  opacity: 0;

  &.delay-short{  animation-delay:$animationDelayShort; }
  &.delay-medium{ animation-delay:$animationDelayMedium; }
  &.delay-long{   animation-delay:$animationDelayLong; }
}


///////////////////////////////////////////////////////////// FADEIN
.fadeIn{
	animation:customFadeIn ease-out $speed forwards;
  &.slow{   animation-duration: $speedSlow; }
  &.slower{ animation-duration: $speedSlower; }
  &.fast{ animation-duration: $speedFast; }
}
@keyframes customFadeIn { from { opacity:0; } to { opacity:1; } }



///////////////////////////////////////////////////////////// SCALE UP
.scaleUp, .scaleUpMedium, .scaleUpBig{
  &.slow{   animation-duration: $speedSlow; }
  &.slower{ animation-duration: $speedSlower; }
  &.fast{ animation-duration: $speedFast; }
}

.scaleUp{
	animation:customScaleUp ease-out $speed forwards;
  transform:scale(0.96);
}
@keyframes customScaleUp { from { transform:scale(0.96); } to { transform:scale(1); opacity:1; } }


.scaleUpMedium{
	animation:customScaleUpMedium ease-out $speed forwards;
  transform:scale(0.7);
}
@keyframes customScaleUpMedium { from { opacity:0; transform:scale(0.7); } to { opacity:1; transform:scale(1); } }


.scaleUpBig{
  animation:customScaleUpBig ease-out $speed forwards;
  transform:scale(0.5);
}
@keyframes customScaleUpBig { from { opacity:0; transform:scale(0.5); } to { opacity:1; transform:scale(1); } }



///////////////////////////////////////////////////////////// SCALE DOWN
.scaleDown, .scaleDownMedium, .scaleDownBig{
  &.slow{   animation-duration: $speedSlow; }
  &.slower{ animation-duration: $speedSlower; }
  &.fast{ animation-duration: $speedFast; }
}

.scaleDown{
	animation:customScaleDown ease-out $speed forwards;
  transform:scale(1.04);
}
@keyframes customScaleDown { from { transform:scale(1.04); } to { transform:scale(1); opacity:1; } }


.scaleDownMedium{
	animation:customScaleDownMedium ease-out $speed forwards;
  transform:scale(1.3);
}
@keyframes customScaleDownMedium { from { opacity:0; transform:scale(1.3); } to { opacity:1; transform:scale(1); } }


.scaleDownBig{
  animation:customScaleDownBig ease-out $speed forwards;
  transform:scale(1.5);
}
@keyframes customScaleDownBig { from { opacity:0; transform:scale(1.5); } to { opacity:1; transform:scale(1); } }



///////////////////////////////////////////////////////////// SLIDE
.slideRight, .slideLeft, .slideUp, .slideDown{
  &.slow{   animation-duration: $speedSlow; }
  &.slower{ animation-duration: $speedSlower; }
  &.fast{ animation-duration: $speedFast; }
}

.slideRight{
	animation:customSlideRight ease-out $slideDuration forwards;
  transform:translate(-$slideDistance,0);
}
@keyframes customSlideRight { from { opacity:0; transform:translate(-$slideDistance,0); } to { transform:translate(0,0); opacity:1; } }

.slideLeft{
	animation:customSlideLeft ease-out $slideDuration forwards;
  transform:translate($slideDistance,0);
}
@keyframes customSlideLeft { from { opacity:0; transform:translate($slideDistance,0); } to { transform:translate(0,0); opacity:1; } }

.slideUp{
	animation:customSlideUp ease-out $slideDuration forwards;
  transform:translate(0, $slideDistance);
}
@keyframes customSlideUp { from { opacity: 0; transform:translate(0, $slideDistance); } to { transform:translate(0,0); opacity:1; } }

.slideDown{
  animation:customSlideDown ease-out $slideDuration forwards;
  transform:translate(0, -$slideDistance);
}
@keyframes customSlideDown { from { opacity: 0; transform:translate(0, -$slideDistance); } to { transform:translate(0,0); opacity:1; } }

