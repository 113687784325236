@import 'layout/_reset.scss';
@import '_variables.scss';
@import 'layout/_fonts.scss';
@import 'layout/_forms.scss';
@import 'layout/_columns.scss';
@import 'layout/_animations.scss';
//@import 'layout/_table.scss';

@import 'components/_components.scss';
@import 'components/_buttons.scss';
@import 'components/_iconpack.scss';

// TIP: To import a css file from nodemodules, prefix it with:  ~
// @import '~@syncfusion/ej2-base/styles/material.css';

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Regular.ttf') format('truetype');
}

//////////////////////////////////////////// *, BODY, HTML
html,
body {
  width: $app-width;
  height: $app-height;
  background-color: #FFF;
  font-family: $font; // see variables.scss
  color: $color-text;

  letter-spacing: 0.03rem;

  @media screen and (orientation:landscape){
    font-size: 18px;
    line-height: 1.6rem;
  }
  
  @media screen and (orientation:portrait){
    font-size: 16px;
    line-height: 1.6rem;
  }  
}


//////////////////////////////////////////// DEFAULTS
strong,
b {
  font-weight: 600;
}

small {
  font-size: 0.85em;
}

.big {
  font-size: 1.2em;
}

.bigger {
  font-size: 1.4em;
}

a {
  text-decoration: none;
  font-weight: 600;
}

hr {
  border-top: 1px solid $color-line;
  margin: 0.8rem 0;
}


//////////////////////////////////////////// HEADER TAGS
h1,
h2,
h3,
h4 {
  color: $color-title;
  line-height: 1em;
	margin-bottom: 1em;
	font-weight: 400;
}

h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 3rem;
}
h4 {
  font-size: 3rem;
}



// --------------------------------------------- APP-VIEW
.app-view {
  margin: auto;
  max-width: 1200px;
  height: 100%;

  &.padded {
    padding: 0 2rem;
  }
}


// --------------------------------------------- ALIGN VERT + HOR
/* <div class="align-vert align-hor">
  <div>centered</div>
</div> */
.align-vert {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;

  &.align-hor {
    width: 100%;
    justify-content: center;

    >* {

      &:before,
      &:after {
        display: block;
        content: '';
        align-self: flex-start;
      }

      &:after {
        align-self: flex-end;
      }
    }
  }
}



// --------------------------------------------- FULLHEIGHT
// Fill a div to 100% height, and flex the 2 children so they take up all the space
/* <div class="fullheight-container">
  <div>top div</div>
  <div class="fillspace">this div will take up the remaining space</div>
</div> */
.fullheight-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .fillspace {
    flex-grow: 1;
    overflow: scroll; // remove this if the div cannot be smaller than its contents
  }
}



// --------------------------------------------- CUSTOM SCROLLBAR
::-webkit-scrollbar {
  width: 20px;
  display: none; // FIX: removed because it takes up spacing, even if no scrollbars are present
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #BBB;
  margin-right: 5px;
  width: 4px;
  border-right: 10px solid white;
}



// --------------------------------------------- DEBUG
.debug {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFF;
  font-size: 12px;
  line-height: 16px;
  padding: 5px;
  pointer-events: none;
  z-index: 9999;
  display: none;

  p {
    margin: 4px 8px;
    white-space: nowrap;
  }
}

pre {
  font-size: 11px;
  line-height: 14px;
  font-family: monospace;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #111;
}

.development {
  .debug {
    display: block;
  }
}


// --------------------------------------------- IMG without SRC: hide outline:
img:not([src]),
img[src=""] {
  visibility: hidden;
}
