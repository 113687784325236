//@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600');


/*
@font-face {
	font-family: 'my_custom_font';		// don't use the same exact name as the font on your system
	src: url('./../../assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'my_custom_font';
	src: url('./../../assets/fonts/open-sans-v15-latin-300.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'my_custom_font';
	src: url('./../../assets/fonts/open-sans-v15-latin-600.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
*/