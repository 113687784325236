//----------------------------------------------------- PRELOAD DIRECTIVE
img.preload{
  opacity: 0;
  transition:opacity 0.2s ease-out;
}
img.preload.loaded{
  opacity: 1;
}


