@use 'sass:math';

// [INFO] See route: /components for a preview

// EXAMPLE
// .div{
// 		@media #{$mq-medium}{ ... }
// }

// SETTINGS:
$padding:3%;
$margin_bottom:20px;


// GENERATE VALUES:
$half:math.div((100%-$padding), 2);				// 48%
$third:math.div((100%-($padding*2)), 3);		// 30.66%
$quarter:math.div((100%-($padding*3)), 4);	// 22%

// .half.w20:
$half10:((100%-$padding)*0.1);
$half20:((100%-$padding)*0.2);
$half30:((100%-$padding)*0.3);
$half40:((100%-$padding)*0.4);
$half50:((100%-$padding)*0.5);
$half60:((100%-$padding)*0.6);
$half70:((100%-$padding)*0.7);
$half80:((100%-$padding)*0.8);
$half90:((100%-$padding)*0.9);

// .third.w20:
$third10:((100%-($padding*2))*0.1);
$third20:((100%-($padding*2))*0.2);
$third30:((100%-($padding*2))*0.3);
$third40:((100%-($padding*2))*0.4);
$third50:((100%-($padding*2))*0.5);
$third60:((100%-($padding*2))*0.6);
$third70:((100%-($padding*2))*0.7);
$third80:((100%-($padding*2))*0.8);
$third90:((100%-($padding*2))*0.9);


.row{ clear:both; }
.row:after{ content:"."; display:block; height:0; clear:both; visibility:hidden; font-size:0; }
html.ie7 .row{ min-height:1%; }


.col{
	float:left;
	margin-left:$padding;
	margin-left:($padding*0.5)\9;	/* ie7/8 only */
	margin-bottom:$margin_bottom;
}

.col.half{ width:$half; }
.col.half.w10{ width:$half10; }
.col.half.w20{ width:$half20; }
.col.half.w30{ width:$half30; }
.col.half.w40{ width:$half40; }
.col.half.w50{ width:$half50; }
.col.half.w60{ width:$half60; }
.col.half.w70{ width:$half70; }
.col.half.w80{ width:$half80; }
.col.half.w90{ width:$half90; }

.col.third{ width:$third; }
.col.third.colspan2{ width:(($third*2)+$padding); }
.col.third.w10{ width:$third10; }
.col.third.w20{ width:$third20; }
.col.third.w30{ width:$third30; }
.col.third.w40{ width:$third40; }
.col.third.w50{ width:$third50; }
.col.third.w60{ width:$third60; }
.col.third.w70{ width:$third70; }
.col.third.w80{ width:$third80; }
.col.third.w90{ width:$third90; }

.col.quarter{ width:$quarter; }


.col.half:nth-of-type(2n+1),
.col.third:nth-of-type(3n+1),
.col.quarter:nth-of-type(4n+1){
	margin-left:0;
	clear:left;
}

/* QUARTER: reduce to 3 columns */
@media #{$mq-large}{
	.col.quarter{
		width:$third;
	}
	.col.quarter:nth-of-type(4n+1){
		margin-left:$padding;
		clear:none;
	}
	.col.quarter:nth-of-type(3n+1),
	.col.quarter:first-child{
		margin-left:0;
		clear:left;
	}
	.col.quarter.no-third{
		width:$quarter;
	}
	.col.quarter.no-third:nth-of-type(4n+1){
		margin-left:0;
		clear:left;
	}
	.col.quarter.no-third:nth-of-type(3n+1){
		margin-left:$padding;
		clear:none;
	}
	.col.quarter.no-third:first-child{
		margin-left:0;
	}
}


/* THIRD: reduce to 2 columns */
@media #{$mq-medium}{
	.col{
		margin-bottom:10px;
	}
	.col.third, .col.third.colspan2, .col.quarter, .col.quarter.no-third{
		width:$half;
	}
	.col.third:nth-of-type(3n+1),
	.col.quarter:nth-of-type(3n+1){
		margin-left:$padding;
		clear:none;
	}

	.col.third:nth-of-type(2n+1),	.col.third:first-child,
	.col.quarter:nth-of-type(2n+1), .col.quarter:first-child{
		margin-left:0;
		clear:left;
	}

	/* disable reduce to half column */
	.col.third.no-half,
	.col.quarter.no-half{
		width:$third;
	}
	.col.third.no-half:nth-of-type(2n+1),.col.third.no-half:first-child,
	.col.quarter.no-half:nth-of-type(2n+1),.col.quarter.no-half:first-child{
		margin-left:$padding;
		clear:none;
	}
	.col.no-half.third:nth-of-type(3n+1),
	.col.no-half.quarter:nth-of-type(3n+1){
		margin-left:0;
		clear:left;
	}
}


/* col-sm */
@media #{$mq-medium}{
	.col-sm, .col-sm.half, .col-sm.third, .col-sm.third.colspan2, .col-sm.quarter{
		width:100%!important;
		margin-left:0!important;
		clear:none;
	}
	.col-sm.no-single{	/* disable single column */
		width:$half!important;
		margin-left:$padding!important;
	}
	.col-sm.no-single:nth-of-type(2n+1){
		margin-left:0!important;
	}
}


/* reduce to fullwidth */
@media #{$mq-mobile}{
	.col, .col.half, .col.third, .col.third.colspan2, .col.quarter{
		width:100%!important;
		margin-left:0!important;
		clear:none;
	}
	.col.no-single{	/* disable single column */
		width:$half!important;
		margin-left:$padding!important;
	}
	.col.no-single:nth-of-type(2n+1){
		margin-left:0!important;
	}
}

/* reduce to fullwidth */
@media #{$mq-mobile}{
	.col.no-single.half{ width:$half!important; }
	.col.no-single.half.w20{ width:$half20!important; }
	.col.no-single.half.w30{ width:$half30!important; }
	.col.no-single.half.w40{ width:$half40!important; }
	.col.no-single.half.w50{ width:$half50!important; }
	.col.no-single.half.w60{ width:$half60!important; }
	.col.no-single.half.w70{ width:$half70!important; }
	.col.no-single.half.w80{ width:$half80!important; }

	.col.no-single.third{ width:$third!important; }
	.col.no-single.third.colspan2{ width:(($third*2)+$padding)!important; }
	.col.no-single.third.w20{ width:$third20!important; }
	.col.no-single.third.w30{ width:$third30!important; }
	.col.no-single.third.w40{ width:$third40!important; }
	.col.no-single.third.w50{ width:$third50!important; }
	.col.no-single.third.w60{ width:$third60!important; }
	.col.no-single.third.w70{ width:$third70!important; }
	.col.no-single.third.w80{ width:$third80!important; }
}
