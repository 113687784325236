*{
	box-sizing: border-box;
}
*:focus{ outline:none; }
body{
	min-height:100%;
  line-height:1;
	-webkit-touch-callout:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-tap-highlight-color: transparent;
}
html{
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust:100%;
	-moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
	touch-action: manipulation;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, tt, var, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{
	margin:0; padding:0; border:0;
	font-size:100%;
	vertical-align:baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{ display:block; }

b, strong{ font-weight:bold; }
i{ font-style:italic; }
em{ font-style:normal; }
ol, ul{	list-style:none;}
img{ border:0; }
a{	outline:none;}
table{ border-collapse:collapse; border-spacing:0; }

///////////////////////////////////////////////////////////////////// FORMS
textarea{
	overflow:auto;
	resize:none;
}

input, textarea, button, select{
	margin:0;
	padding:0;
	font-size:100%;
	line-height:normal;
	vertical-align:baseline;
	outline:none;
	border:none;
}
textarea, button{ /*input*/
	-webkit-appearance:none;
}

input[type="radio"]{
	-webkit-border-radius:10px;
}
button, input[type="button"], input[type="reset"], input[type="submit"]{
	cursor:pointer;
	-webkit-appearance:button;
}
input[type="checkbox"], input[type="radio"]{
	box-sizing:border-box;
}
input[type="search"],
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration{
    -webkit-appearance:none;
}

/* ios: remove input shadow */
html.safari_mobile input, html.safari_mobile textarea{
	-webkit-appearance:caret;
	-moz-appearance:caret;
}

