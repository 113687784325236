$icons:
  arrow-left
  arrow-right
  close
  remove
  search;

.iconpack {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;

  &.small {
    width: 30px;
    height: 30px;
  }

  @each $icon in $icons {
		&.#{$icon} {
			background-image: url('./../../assets/icons/default/#{$icon}.svg');
		}
	}
}
