//----------------------------------- BUTTON
/*
<button>Some button</button>

<button>
	<span class="iconpack arrow-back"></span>
	<span class="label">back</span>
</button>
*/
.button,
button{
	display:inline-block;
	position:relative;
  padding: 0.4rem 1.2rem 0.6rem 1.2rem;
  border-radius:0.4rem;
  color: #FFF;
  background-color: $color-primary;
  transition:all 0.08s ease-out;
  font-family: $font;
  text-align: center;
  transition: all 0.12s ease-out;
  color: #FFF;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover{
    background-color: $color-tint;
  }

  .iconpack{
    vertical-align: middle;
  }

  // BUTTON STATES:
	&.disabled,
  &:disabled{
    opacity: 0.5;
    pointer-events: none;
		filter: saturate(0);
  }

  &.outline{
    background-color: transparent;
    border: 1px solid $color-line;
    color: $color-text;
  }

  &.text{
    background-color: transparent;
    border: none;
    color: $color-text;
  }

  &.tint{
    background-color: $color-tint;
  }

  &.big{
    font-size: 1.4rem;
    line-height: 1.6rem;
    height: auto;
    padding: 0.5rem 2rem 0.7rem 2rem;
  }

  &.small{
    font-size: 0.85rem;
    line-height: 1.1rem;
    padding: 0.3rem 0.8rem 0.35rem 0.8rem;
  }
}
